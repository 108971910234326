import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// console.debug("init"); // TODO

Vue.use(VueRouter);

// console.debug("i2"); // TODO
/**
 * Wraps Vue Router - push() and replace()
 * used to catch NavigationDuplicatedError which is thrown if multiple widgets are used on the same page!
 */

["push", "replace"].forEach(method => {
  //console.debug("i3"); // TODO
  /** @ts-ignore */
  const originalMethod = VueRouter.prototype[method];

  //console.debug("i4"); // TODO

  if (!originalMethod) {
    return;
  }

  //console.debug("i5"); // TODO
  /** @ts-ignore */
  VueRouter.prototype[method] = function m(location) {
    // console.debug("i7"); // TODO
    // console.log("originalMethod called", originalMethod);
    const result = originalMethod
      ?.call(this, location)
      .catch((error: Error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
        console.warn(
          "NavigationDuplicatedError - if the InnoFind-Widget is used twice on the same page this is by design. - ",
          error.name,
          error.message
        );
      });
    // console.log("done"); // TOOD
    return result;
  };
});
// console.debug("i6"); // TODO

export const INNOFIND_SESSIONSTORAGE_ROUTE_FROM_NAME = "if-route-from";

const routes: Array<RouteConfig> = [
  {
    path: "/plp/:category",
    name: "plp",
    component: () => import("../components/pages/PLP.vue"),
    meta: {
      keepAlive: true,
      mtmPath: "plp"
    },
    beforeEnter: (to, from, next) => {
      if (sessionStorage) {
        sessionStorage.setItem(
          INNOFIND_SESSIONSTORAGE_ROUTE_FROM_NAME,
          from?.name || ""
        );
      }

      next();
    }
  },
  {
    path: "/define/:category",
    name: "define",
    component: () => import("../components/pages/DefineRoot.vue"),
    meta: {
      mtmPath: "define"
    }
  },
  {
    path: "/plp/:category/likes",
    name: "likes",
    component: () => import("../components/pages/Likes.vue"),
    meta: {
      keepAlive: false,
      scrollToTop: true,
      transitionName: "slide",
      mtmPath: "plp"
    }
  },
  {
    path: "/plp/:category/tastesuggestions",
    name: "tastesuggestions",
    component: () => import("../components/pages/TasteSuggestions.vue"),
    meta: {
      keepAlive: false,
      scrollToTop: true,
      transitionName: "slide",
      mtmPath: "tastesuggestions"
    }
  },
  {
    path: "/pdp/:category/:itemid",
    name: "pdp",
    component: () => import("../components/pages/PDPWidgetRoot.vue"),
    meta: {
      keepAlive: false,
      mtmPath: "pdp"
    }
  },
  {
    path: "/plp/:category/debugsimilarities",
    name: "debugsimilarities",
    component: () => import("../components/pages/DebugSimilareImagesPage.vue"),
    meta: {
      keepAlive: false,
      transitionName: "slide-bottom",
      mtmPath: "plp"
    }
  },
  {
    path: "/plp/:category/similarities",
    name: "similarities",
    component: () => import("../components/pages/SimilarImagesPage.vue"),
    meta: {
      keepAlive: false,
      scrollToTop: true,
      transitionName: "slide-bottom",
      mtmPath: "similarity"
    }
  }
];

const router = new VueRouter({
  mode: "abstract",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  if (to.meta.scrollToTop) {
    // check if user is below or above innofind-app (only scroll is user is below)
    const userIsBelowInnoFindApp =
      window.pageYOffset >
      Number(document.getElementById("innofind-app")?.offsetTop);

    if (userIsBelowInnoFindApp) {
      document.getElementById("innofind-app")?.scrollIntoView();
    }
  }

  if (to.name == "plp" && from.query.plpScrollState) {
    to.query.plpScrollState = from.query.plpScrollState;
  }

  next();
});
export default router;
