import "document-register-element/build/document-register-element"; //Optional Custom Elements Polyfill (IE9+)
import "scroll-behavior-polyfill"; // polyfill to enable smooth scroll to in safari
import Vue from "vue";
import vueCustomElement from "vue-custom-element";
import { ObserveVisibility } from "vue-observe-visibility";
import Vuex from "vuex";
import router from "./router";
import WebComponentRoot from "./WebComponentRoot.vue";

require("intersection-observer");
Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(vueCustomElement);
Vue.directive("observe-visibility", ObserveVisibility);

Vue.customElement("innofind-widget", new WebComponentRoot().$options, {
  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "category") {
      const newRoute = "/" + newValue;
      if (router.currentRoute.path !== newRoute) {
        router.push({ path: newRoute });
      }
    }
  }
});

Vue.customElement("innofind-widget-pdp", new WebComponentRoot().$options, {
  constructorCallback() {
    //@ts-ignore
    this.entrypoint = "pdp";
  }
});

Vue.customElement(
  "innofind-widget-interactive-productlist",
  new WebComponentRoot().$options,
  {
    constructorCallback() {
      //@ts-ignore
      this.forceplp = "true";
    }
  }
);

Vue.customElement(
  "innofind-widget-interactive-teaser",
  new WebComponentRoot().$options,
  {}
);
